// Determine the API base URL based on the environment
export const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? 'api'
  : `http://localhost:8000/api`;

// Define the endpoints
export const LOGIN_ENDPOINT = `${API_BASE_URL}/auth/email/`;
export const REFRESH_TOKEN_ENDPOINT = `${API_BASE_URL}/auth/refresh/`;
export const OTP_VERIFICATION_ENDPOINT = `${API_BASE_URL}/auth/email/verify-otp/`;

export const UPLOAD_HISTORY_ENDPOINT = `${API_BASE_URL}/logs/upload-history/`;
export const MONTHLY_HOLDINGS_ENDPOINT = `${API_BASE_URL}/investments/holdings/monthly/`;
export const DAILY_HOLDINGS_ENDPOINT = `${API_BASE_URL}/investments/holdings/daily/`;
export const CLIENT_ENDPOINT = `${API_BASE_URL}/clients/`;
export const FILE_UPLOAD_ENDPOINT = `${API_BASE_URL}/logs/upload-csv/`;


export const USERS_ENDPOINT = `${API_BASE_URL}/users/`;
export const USERS_CREATE_ENDPOINT = `${API_BASE_URL}/users/`;
export const USERS_EDIT_ENDPOINT = `${API_BASE_URL}/users/`;

export const TEMPLATE_ENDPOINT = `${API_BASE_URL}/templates/`;
export const TEMPLATE_CREATE_ENDPOINT = `${API_BASE_URL}/templates/create/`;
export const TEMPLATE_DELETE_ENDPOINT = `${API_BASE_URL}/templates/delete/`;

export const TEMPLATE_BROADCAST_ENDPOINT = `${API_BASE_URL}/templates/broadcast/`;

export const WHATSAPP_MESSAGE_LOG_ENDPOINT = `${API_BASE_URL}/whatsapp/messages/logs/`;