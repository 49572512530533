import React from 'react';
import SetupTemplate from './SetupTemplate';
import Container from '@mui/material/Container';

const CreateTemplate = (props) => { 

  const { templatesNamesData, setComponent } = props;

  return (
    <div className="App">
      <Container maxWidth={false}>
        <SetupTemplate 
          key={props.refreshKey} 
          templatesNamesData={templatesNamesData}
          setComponent={setComponent}
        />
      </Container>

    </div>
  );
}
export default CreateTemplate;
