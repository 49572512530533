import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { MONTHLY_HOLDINGS_ENDPOINT } from '../config/urls';


const columns = [
  { id: ['client.client_code'], label: 'Client Code' },
  { id: 'name', label: 'Name' },
  { id: 'phone', label: 'Phone Number' },
  { id: 'val_date', label: 'Value Date' },
  { id: 'one_month_return', label: 'One Month Return (%)' },
  { id: 'six_month_return', label: 'Six Month Return (%)' },
  { id: 'one_year_return', label: 'One Year Return (%)' },
  { id: 'three_year_return', label: 'Three Year Return (%)' },
  { id: 'five_year_return', label: 'Five Year Return (%)' },
  { id: 'since_inception_return', label: 'Since Inception Return (%)' },
];

export default function MonthlyHoldingsTable({ searchInput, refreshKey, dateInput }) {
  const [rowCount, setRowCount] = useState(0);
  const [holdings, setHoldings] = useState([]);
  const [searchInputVar, setSearchInputVar] = useState('');
  const URL = process.env.NODE_ENV == 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  useEffect(() => {
    setSearchInputVar(searchInput);
    setController({
      page: 0,
      rowsPerPage: 20
    });
  }, [searchInput]);

  useEffect(() => {
    let searchURL = '';
    let searchDateURL = '';

    if (searchInputVar) {
      searchURL = `&search_value=${searchInputVar}`;
    }

    if (dateInput)  {
      let dateString = dayjs(dateInput).format("YYYY-MM");
        searchDateURL = `&search_date=${dateString}`;
    }

    const fetchData = async () => {
      try {
        const response = await fetch( MONTHLY_HOLDINGS_ENDPOINT + `?limit=${controller.rowsPerPage}&offset=${controller.page * controller.rowsPerPage}` + searchURL + searchDateURL);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setHoldings(jsonData.results);
        setRowCount(jsonData.count);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
    fetchData();
  }, [controller, searchInputVar, refreshKey, dateInput]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  <h3>{column.label}</h3>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {holdings.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No match found
                </TableCell>
              </TableRow>
            ) : (
              holdings.map((holding, index) => (
                <TableRow key={index}>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.client.client_code}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.client.client_name}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.client.phone_no}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.val_date}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.one_month_return}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.six_month_return}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.one_year_return}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.three_year_return}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.five_year_return}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{holding.since_inception_return}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={rowCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

