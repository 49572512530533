import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@mui/material';

const PDFPreview = ({ pdfFile }) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!pdfFile) {
      setError('No PDF file provided');
      setLoading(false);
      return;
    }

    try {
      // Handle both URL strings and File objects
      const url = typeof pdfFile === 'string' ? pdfFile : URL.createObjectURL(pdfFile);
      setPdfUrl(url);
      setLoading(false);

      // Cleanup object URL when component unmounts
      return () => {
        if (typeof pdfFile !== 'string') {
          URL.revokeObjectURL(url);
        }
      };
    } catch (err) {
      setError('Error loading PDF');
      setLoading(false);
    }
  }, [pdfFile]);

  if (loading) {
    return (
      <Card className="w-[220px] h-[280px] flex items-center justify-center bg-gray-50">
        <CardContent>
          <div className="text-gray-500">Loading PDF...</div>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card className="w-[220px] h-[280px] flex items-center justify-center bg-gray-50">
        <CardContent>
          <div className="text-red-500">{error}</div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-[220px] h-[280px] overflow-hidden">
      <CardContent className="p-0">
        <object
          data={pdfUrl}
          type="application/pdf"
          className="w-full h-full"
        >
          <div className="flex items-center justify-center h-full bg-gray-50">
            <p className="text-gray-500">
              PDF preview not available.
              <br />
              Please download to view.
            </p>
          </div>
        </object>
      </CardContent>
    </Card>
  );
};

export default PDFPreview;