import React, { useState, useEffect } from 'react';

import {
    Select,
    MenuItem,
    Grid,
    Typography,
    Container,
    TextField,
    Button,
    FormControlLabel,
    Alert,
} from '@mui/material';

import MessageNode from "./MessageNode";
import { TEMPLATE_ENDPOINT, TEMPLATE_BROADCAST_ENDPOINT } from '../../config/urls';

import axios from 'axios';

const BroadcastTemplates = (props) => {

    const [selectedTemplate, setSelectedTemplate] = useState('none');
    const [templateOptions, setTemplateOptions] = useState([]);
    const [templateData, setTemplateData] = useState(null);
    const [templatePreviewData, setTemplatePreviewData] = useState(null);
    const [apiData, setApiData] = useState(null);
    const [requiredFields, setRequiredFields] = useState({});

    const [requiredFieldsToRender, setRequiredFieldsToRender] = useState([]);

    const [formValues, setFormValues] = useState({});

    const [alertObj, setAlertObj] = useState({
        active: false,
        msg: "",
        type: "",
        statusCode: ""
    });

    const handleAlertClose = () => {
        setAlertObj({
        active: false,
        type: "",
        msg: "",
        statusCode: ""
        });
    };

    // console.log("Template JSON Data", templateData);

    const fetchData = async () => {
        try {
        const response = await fetch(TEMPLATE_ENDPOINT);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();

        console.log("GET API response", jsonData);

        setApiData(jsonData);
        setTemplateOptions(jsonData.results.map(item => item.template_name));
        setRequiredFields(jsonData.required_fields);
    
        console.log(jsonData.results);
        } catch (error) {
        console.error('Error fetching data:', error.message);
        }
    };

    const handleTemplateChange = (event) => {
        const selectedTemplateName = event.target.value;
        setSelectedTemplate(selectedTemplateName);

        // Reset form values when switching back to "Select a template"
        if (selectedTemplateName === 'none') {
            setFormValues({});
            setRequiredFieldsToRender([]);
            setTemplatePreviewData(null);
        } else {
            // console.log("selectedTemplateName: ", selectedTemplateName);
            // Find the template preview data based on the selected template name
            const selectedTemplate = apiData?.results.find(item => item.template_name === selectedTemplateName);
            if (selectedTemplate) {
                console.log("apiData: ", apiData);
                setTemplatePreviewData(selectedTemplate.template_preview_json);
                setTemplateData(selectedTemplate.template_json);
            } else {
                setTemplatePreviewData(null);
                setTemplateData(null);
            }

            // Get the required fields for the selected template
            const requiredFieldsForTemplate = requiredFields[selectedTemplateName];
            if (requiredFieldsForTemplate) {
                const fieldsToRender = [];
                const updatedFormValues = { ...formValues };

                if (requiredFieldsForTemplate.includes('hparameter')) {
                    fieldsToRender.push(
                        <Grid container direction="column" key="hparameter">
                            <TextField xs={12} sx={{ marginTop: 2, width: '50%' }} name='hparameter' key="hparameter" label="Header Parameter" onChange={handleInputChange} required />
                        </Grid>
                    );
                    updatedFormValues.hparameter = '';
                }
                if (requiredFieldsForTemplate.includes('bparameter')) {
                    const bparameterCount = requiredFieldsForTemplate.filter(field => field === 'bparameter').length;
                    for (let i = 0; i < bparameterCount; i++) {
                        fieldsToRender.push(
                            <Grid container direction="column" key={`bparameter-${i}`}>
                                <TextField xs={12} sx={{ marginTop: 2, width: '50%' }} name={`bparameter-${i}`} key={`bparameter-${i}`} label={`Body Parameter ${i+1}`} onChange={handleInputChange} required />
                            </Grid>
                        );
                        updatedFormValues[`bparameter-${i}`] = '';
                    }
                }
                if (requiredFieldsForTemplate.includes('urlparameter')) {
                    fieldsToRender.push(
                        <Grid container direction="column" key="urlparameter">
                            <TextField xs={12} sx={{ marginTop: 2, width: '90%' }} name='urlparameter' key="urlparameter" label="URL Parameter" onChange={handleInputChange} required />
                        </Grid>
                    );
                    updatedFormValues.urlparameter = '';
                }

                setFormValues(updatedFormValues);
                setRequiredFieldsToRender(fieldsToRender);
            } else {
                setRequiredFieldsToRender([]);
            }
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // setFormValues({ ...formValues, [name]: value });
        setFormValues((prevFormValues) => ({ ...prevFormValues, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (selectedTemplate === 'none') {
            return;
        }

        const payload = {
            template_json: templateData,
            ...formValues,
        };

        console.log('Payload:', payload);

        // send POST request to TEMPLATE_BROADCAST_ENDPOINT
        axios.post(TEMPLATE_BROADCAST_ENDPOINT, payload)
            .then(response => {
                console.log('API Response:', response);
                setAlertObj({
                    active: true,
                    type: "success",
                    msg: "Payload Generated & message sent successfully",
                    statusCode: response.status
                });
            })
            .catch(error => {
                console.error('API Error:', error.response.data);
                setAlertObj({
                    active: true,
                    type: "error",
                    msg: "Failed to Generate Payload/Send Message",
                    statusCode: error.response.status
                });
            });

    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Container maxWidth={false}>
            <div sx={{ padding: 2 }}>
                <h2 className="heading-title">Broadcast Templates</h2>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                        <div sx={{ padding: 2 }}>
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                    Select Template
                                </Typography>
                                <Select
                                    sx={{ width: '50%' }}
                                    value={selectedTemplate}
                                    onChange={handleTemplateChange}
                                >
                                    <MenuItem value="none">Select a template</MenuItem>
                                    {templateOptions.map(option => (
                                        <MenuItem key={option} value={option}>{option}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <form onSubmit={handleSubmit}>
                                {requiredFieldsToRender}
                                <br />
                                <Button variant="contained" type="submit" sx={{ marginTop: 2 }} >Broadcast</Button>
                            </form>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <MessageNode 
                            templateName={templatePreviewData ? templatePreviewData.template_name : ''}
                            language={templatePreviewData ? templatePreviewData.language : 'english'}
                            headerType={templatePreviewData ? templatePreviewData.headerType : 'none'}
                            headerText={templatePreviewData ? templatePreviewData.headerText : ''}
                            headerFile={templatePreviewData ? templatePreviewData.headerFile : null}
                            body={templatePreviewData ? templatePreviewData.body : 'No template selected'}
                            sampleBodyFields={templatePreviewData ? templatePreviewData.sampleBodyFields : []}
                            footer={templatePreviewData ? templatePreviewData.footer : ''}
                            allButtons={templatePreviewData ? templatePreviewData.allButtons : []}
                            mode='view'
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                    {alertObj.active && (
                        <Alert severity={alertObj.type} onClose={handleAlertClose}>
                        {alertObj.msg} (Status Code: {alertObj.statusCode})
                        </Alert>
                    )}
                </Grid>
            </div>
        </Container>
    );
};
export default BroadcastTemplates;
