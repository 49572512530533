import * as React from "react";
import { useState, useEffect } from 'react';
import Header from './Header.js';
import Footer from './Footer.js';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import axios from 'axios';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTheme } from '@mui/material/styles';
import PeopleIcon from '@mui/icons-material/People';
import MessageIcon from '@mui/icons-material/Message';
import PersonIcon from '@mui/icons-material/Person';
import TodayIcon from '@mui/icons-material/Today';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;

// Set default dates
const startOfMonth = dayjs().startOf('month');
const today = dayjs();

const Dashboard = (props) => {
  const theme = useTheme();
  const [fromDate, setFromDate] = useState(startOfMonth);
  const [toDate, setToDate] = useState(today);
  const [totalMessages, setTotalMessages] = useState(0);
  const [messagesToday, setMessagesToday] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [newUsers, setNewUsers] = useState(0);
  const [dateError, setDateError] = useState(null);

  const fetchData = async (fromDate, toDate) => {
    const messageCountsUrl = `${URL}/kwiqreply/analytics/message-counts/`;
    const messagePercentageUrl = `${URL}/kwiqreply/analytics/logs/`;

    let params = {};
    if (fromDate && toDate && (dayjs(fromDate).isBefore(toDate) || dayjs(fromDate).isSame(toDate))) {
      params = {
        from_date: fromDate.format('YYYY-MM-DD'),
        to_date: toDate.format('YYYY-MM-DD')
      };
    }

    try {
      const [messageCountsResponse, messagePercentageResponse] = await Promise.all([
        axios.get(messageCountsUrl, { params }),
        axios.get(messagePercentageUrl, { params })
      ]);

      if (messageCountsResponse.data) {
        setTotalMessages(messageCountsResponse.data.total_messages || 0);
      }

      if (messagePercentageResponse.data) {
        setMessagesToday(messagePercentageResponse.data.total_messages_till_period || 0);
        setActiveUsers(messagePercentageResponse.data.active_users || 0);
        setNewUsers(messagePercentageResponse.data.active_new_users || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(fromDate, toDate);
  }, [fromDate, toDate]);

  useEffect(() => {
    if (!fromDate && !toDate) {
      fetchData(fromDate, toDate);
    }
  }, []);

  const handleFromDateChange = (newValue) => {
    if (toDate && newValue && dayjs(newValue).isAfter(toDate)) {
      setDateError('From Date must be less than or equal to To Date');
    } else {
      setDateError(null);
      setFromDate(newValue);
    }
  };

  const handleToDateChange = (newValue) => {
    if (fromDate && newValue && dayjs(newValue).isBefore(fromDate)) {
      setDateError('To Date must be greater than or equal to From Date');
    } else {
      setDateError(null);
      setToDate(newValue);
    }
  };

  const handleSubmit = () => {
    fetchData(fromDate, toDate);
  };

  return (
    <div className="App">
      <Header userState={props.userState} />
      <Container maxWidth={false}>
        <h2 className="heading-title">Dashboard</h2>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="body1" component="div">
                  Select Date Range
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 2 }}>
                    <DatePicker
                      label="From Date"
                      value={fromDate}
                      onChange={handleFromDateChange}
                      disableFuture
                      views={['day', 'month', 'year']}
                      sx={{mr: 2}}
                    />
                    <DatePicker
                      label="To Date"
                      value={toDate}
                      onChange={handleToDateChange}
                      disableFuture
                      views={['day', 'month', 'year']}
                    />
                    <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ ml: 2 }} size="large">
                      Search
                    </Button>
                  </Box>
                </LocalizationProvider>
                {dateError && (
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {dateError}
                  </Alert>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Tooltip title="Total number of messages received." arrow>
              <Card sx={{ display: 'flex', alignItems: 'center', p: 4, height: '100px', backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}>
                <MessageIcon sx={{ fontSize: 40, mr: 1 }} />
                <CardContent>
                  <Typography variant="body1" component="div" sx={{ fontSize: 14 }}>
                    Total Client Conversations
                  </Typography>
                  <Typography variant="h4" component="div" sx={{ fontSize: 24 }}>
                    0
                  </Typography>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>

          <Grid item xs={12} md={6}>
            <Tooltip title="Messages received during the selected period." arrow>
              <Card sx={{ display: 'flex', alignItems: 'center', p: 4, height: '100px', backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText }}>
                <TodayIcon sx={{ fontSize: 40, mr: 1 }} />
                <CardContent>
                  <Typography variant="body1" component="div" sx={{ fontSize: 14 }}>
                  Total Unique Client Conversations
                  </Typography>
                  <Typography variant="h4" component="div" sx={{ fontSize: 24 }}>
                    0
                  </Typography>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>

          <Grid item xs={12} md={6}>
            <Tooltip title="Number of active users at present." arrow>
              <Card sx={{ display: 'flex', alignItems: 'center', p: 4, height: '100px', backgroundColor: theme.palette.success.main, color: theme.palette.success.contrastText }}>
                <PeopleIcon sx={{ fontSize: 40, mr: 1 }} />
                <CardContent>
                  <Typography variant="body1" component="div" sx={{ fontSize: 14 }}>
                   Total Lead Conversations
                  </Typography>
                  <Typography variant="h4" component="div" sx={{ fontSize: 24 }}>
                    0
                  </Typography>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>

          <Grid item xs={12} md={6}>
            <Tooltip title="Number of new users registered." arrow>
              <Card sx={{ display: 'flex', alignItems: 'center', p: 4, height: '100px', backgroundColor: theme.palette.error.main, color: theme.palette.primary.contrastText }}>
                <PersonIcon sx={{ fontSize: 40, mr: 1 }} />
                <CardContent>
                  <Typography variant="body1" component="div" sx={{ fontSize: 14 }}>
                  Total Unique Lead Conversations
                  </Typography>
                  <Typography variant="h4" component="div" sx={{ fontSize: 24 }}>
                    0
                  </Typography>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>

          {/* Additional cards */}
          <Grid item xs={12} md={6}>
            <Tooltip title="Placeholder for another metric." arrow>
              <Card sx={{ display: 'flex', alignItems: 'center', p: 4, height: '100px', backgroundColor: theme.palette.info.main, color: theme.palette.primary.contrastText }}>
              <PeopleIcon sx={{ fontSize: 40, mr: 1 }} />
                <CardContent>
                  <Typography variant="body1" component="div" sx={{ fontSize: 14 }}>
                  Total Clients requesting Live Agent
                  </Typography>
                  <Typography variant="h4" component="div" sx={{ fontSize: 24 }}>
                    0
                  </Typography>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>

          <Grid item xs={12} md={6}>
            <Tooltip title="Placeholder for another metric." arrow>
              <Card sx={{ display: 'flex', alignItems: 'center', p: 4, height: '100px', backgroundColor: theme.palette.warning.main, color: theme.palette.primary.contrastText }}>
              <PersonIcon sx={{ fontSize: 40, mr: 1 }} />
                <CardContent>
                  <Typography variant="body1" component="div" sx={{ fontSize: 14 }}>
                  Total Leads requesting Live Agent
                  </Typography>
                  <Typography variant="h4" component="div" sx={{ fontSize: 24 }}>
                    0
                  </Typography>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;
