import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import WhatsappTable from './WhatsappTable';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CreateTemplate from './CreateTemplate';

const Whatsapp = (props) => { 
  
  const [componentState, setComponent] = useState(1);
  const [templatesNamesData, setTemplatesNamesData] = useState([]);

  const handleCreateTemplateClick = (newState) => {
    setComponent(newState);
  };

  return (
    <div className="App">
      <Container maxWidth={false}>
        {componentState === 1 ? (
          <>
            <h2 className="heading-title">WhatsApp Templates</h2>
            <Button variant="contained" onClick={() => handleCreateTemplateClick(2)}>Create Template</Button>
            <WhatsappTable 
              refreshkey={props.refreshKey} 
              setTemplatesNamesData={setTemplatesNamesData} 
            />
          </>
        ) : (
          <>
            <h2 className="heading-title">WhatsApp Templates <span style={{ opacity: 0.6 }}>{'>'}</span> Create Template</h2>
            <Button variant="contained" onClick={() => handleCreateTemplateClick(1)}>Go Back</Button>
            <CreateTemplate 
              templatesNamesData={templatesNamesData}
              setComponent={setComponent}
            />
          </>
        )}
      </Container>

    </div>
  );
}
export default Whatsapp;
