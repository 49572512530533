import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  FormHelperText,
  Collapse,
  Alert,
  Box,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { USERS_EDIT_ENDPOINT, USERS_CREATE_ENDPOINT } from '../config/urls';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const UserFormDialog = ({ modalState, handleModalState, selectedClient }) => {

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [uploadStatus, setUploadStatus] = useState(null);

  let user_data = JSON.parse(localStorage.getItem("user"));

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    is_active: true
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    is_active: ''
  });

  const [alertObj, setAlertObj] = useState({
    active: false,
    msg: "",
    type: ""
  });

  const handleAlertClose = () => {
    setAlertObj({
      active: false,
      type: "",
      msg: ""
    });
  };

  const handleClose = () => {
    handleModalState({ isOpen: false });
    setAlertOpen(false);
    setFormErrors({
      name: '',
      email: '',
      is_active: ''
    });
  };

  useEffect(() => {
    if (selectedClient) {
      setFormData({
        name: selectedClient.name,
        email: selectedClient.email,
        is_active: selectedClient.is_active,
      });
    } else {
      setFormData({
        name: '',
        email: '',
        is_active: true
      });
    }
  }, [selectedClient]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleToggleChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      is_active: !prevData.is_active,
    }));
  };

  const validateForm = () => {
    const newFormErrors = {};
    let hasErrors = false;
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (formData.name.trim() === '') {
      newFormErrors.name = 'Please enter a name';
      hasErrors = true;
    }
    
    if (formData.email.trim() === '' || !emailPattern.test(formData.email.trim())) {
      newFormErrors.email = 'Please enter a valid email';
      hasErrors = true;
    }

    setFormErrors(newFormErrors);
    return !hasErrors;
  };

  const handleAdd = () => {
    if (!validateForm()) {
      return;
    }

    const apiUrl = USERS_CREATE_ENDPOINT;

    axios.post(apiUrl, formData)
      .then(response => {
        console.log('API Response:', response.data);
        setAlertObj({
          active: true,
          type: "success",
          msg: "Client created successfully"
        });
        handleClose();
      })
      .catch(error => {
        console.error('API Error:', error.response.data);
        setAlertObj({
          active: true,
          type: "error",
          msg: "Failed to create client"
        });
      });
  };

  const handleEdit = () => {
    if (!validateForm()) {
      return;
    }

    const apiUrl =  USERS_EDIT_ENDPOINT + `${selectedClient.id}/`;

    axios.put(apiUrl, formData)
      .then(response => {
        console.log('API Response:', response.data);
        setAlertObj({
          active: true,
          type: "success",
          msg: "Client updated successfully"
        });
        handleClose();
      })
      .catch(error => {
        console.error('API Error:', error.response.data);
        setAlertObj({
          active: true,
          type: "error",
          msg: "Failed to update client"
        });
      });
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalState.isOpen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }} id="customized-dialog-title">
        {modalState.mode === 'edit' ? 'Edit User' : 'Create User'}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Collapse in={alertObj.active}>
          <Alert sx={{ mb: 2 }} severity={alertObj.type} onClose={handleAlertClose}>
            {alertObj.msg}
          </Alert>
        </Collapse>
        <FormControl>
          <Box sx={{ m: 3 }}>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={formData.is_active}
                  onChange={handleToggleChange}
                  name="is_active"
                  color="primary"
                  disabled={ modalState.mode === "edit" && selectedClient.id === user_data.id ? true : false}
                />
              }
              label="Active"
            />
          </Box>
        </FormControl>
      </DialogContent>

      <DialogActions>
        {modalState.mode === 'edit' ? (
          <Button autoFocus onClick={handleEdit}>Edit</Button>
        ) : (
          <Button autoFocus onClick={handleAdd}>Add</Button>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
}

export default UserFormDialog;
